/* APP-SPECIFIC GLOBAL RULES       (imported into app.component.ts styleUrls, right after defaults and typography)
---------------------------------------- */
@import 'functions';
@import 'mixins';
@import 'breakpoints';
@import 'variables';
@import 'hero';

body,
html {
  background-color: $grayLter;
  // min-height: rem-calc(768);
  min-width: rem-calc(320);
}

// this rule helps show the footer at the bottom of the screen
body {
  min-height: 100vh;
  padding-bottom: rem-calc(88); // padding-bottom matches footer height
  position: relative;
}

.blueBg {
  background: $blue;
}

.yellowBg {
  background: $yellow;
}

.noscroll {
  overflow: hidden;
}

.centerText {
  text-align: center;
}

.capitalize {
  text-transform: capitalize;
}

.numberTooltip {
  padding: 1rem;
}

a,
a:visited,
a:link {
  color: $blue;
  transition: color 0.2s ease;

  &:active,
  &:hover {
    color: $yellow;
  }
}

.divider {
  border: none;
  border-bottom: 1px solid $border;
  margin: 2rem 0;
}

@mixin button($background, $color) {
  @include oswald;
  @include medium;
  align-items: center;
  background-color: $background;
  border: none;
  border-radius: 0;
  color: $color;
  display: flex;
  font-size: 16px;
  height: rem-calc(56);
  justify-content: center;
  padding: 0 rem-calc(72);
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.4s, background-color 0.4s;

  &:hover,
  &:active {
    background-color: $color;
    color: $background;
  }

  &:disabled {
    background-color: $gray;
    color: $grayDk;
  }
}

.button {
  @include button($yellow, $blue);
}

.button2 {
  @include button($blue, $yellow);
  border: 2px solid $blue;
}

.buttonFooter {
  @include button($yellow, $blue);

  &:hover,
  &:active {
    background-color: $yellow;
    color: $blue;
  }
}

.buttonText {
  @include button(transparent, $blue);
  padding: 0;

  &:hover,
  &:active {
    background: transparent;
    color: $yellow;
  }

  &--noHover {
    &:hover,
    &:active {
      color: $blue;
    }
  }

  &__icon {
    margin-right: rem-calc(8);
  }
}

.dashButton {
  @include button($blue, $grayLter);
  border: 2px dashed $grayLter;
  height: auto;
  margin: 3rem auto 0;
  padding: 2rem;
  text-align: center;
}

.iconButton {
  align-items: center;
  background: transparent;
  border: none;
  color: $blue;
  display: flex;
  font-size: rem-calc(16);
  height: rem-calc(56);
  justify-content: center;
  transition: color 0.4s;
  width: rem-calc(56);

  &:hover,
  &:active {
    color: $yellow;
  }

  &:focus {
    color: $blue;
  }

  &--48 {
    height: rem-calc(48);
    width: rem-calc(48);
  }
}

.iconTextButton {
  align-items: center;
  background: transparent;
  border: none;
  color: $blue;
  display: flex;
  font-size: rem-calc(16);
  padding: 0;

  i {
    height: rem-calc(48);
    line-height: rem-calc(48);
    width: rem-calc(48);
  }
}

@mixin input-style {
  align-items: center;
  background-color: white;
  border: 1px solid $border;
  border-radius: 0;
  color: $blue;
  display: flex;
  font-size: 16px;
  height: rem-calc(56);
  padding: 0 1rem;
  transition: border-color 0.4s;
}

.input {
  @include input-style;

  &:focus {
    border-color: $blue;
  }

  &--error {
    border-color: $red;
  }

  &--textarea {
    height: rem-calc(160);
    padding: 1rem;
  }
}

input[type='radio'] {
  margin: 0 rem-calc(12);
}

input[type='checkbox'] {
  margin: 0 rem-calc(12) 0 0;
}

.tooltip {
  @include shadow;
  background: white;
  border-radius: 2px;
  color: $blue;
  display: none;
  font-size: rem-calc(14);
  padding: rem-calc(16);
  position: absolute;
  top: 100%;
  z-index: 2000;

  &--open {
    display: block;
  }

  &--extraTop {
    top: 120%;
  }

  &--right {
    right: 0;
  }

  &--links {
    padding: 0;
  }

  &__scrim {
    background: transparent;
    bottom: 0;
    display: none;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1500;

    &--open {
      display: block;
    }
  }

  &__link {
    display: block;
    font-size: rem-calc(16);
    padding: rem-calc(16);
    text-decoration: none;
    white-space: nowrap;
  }
}

// Default table styling
.tableWrap {
  background: white;
  margin-bottom: rem-calc(64);
  padding: rem-calc(32);

  &__inner {
    overflow-x: auto;
  }

  &--transparent {
    background: transparent;
  }
}

table {
  border-collapse: collapse;
  min-width: 896px;
  width: 100%;
}

.table {
  &__logo {
    border-radius: 4px;
    margin-right: rem-calc(12);
    width: rem-calc(48);
  }

  &__link {
    text-decoration: underline;
  }

  &__flex {
    align-items: center;
    display: flex;
    line-height: 2;
  }

  &__numberWrap {
    position: relative; // allows tooltip to show in the right place
  }

  &__number {
    text-decoration: none;
  }

  &__iconButton {
    margin: 0 auto;
  }

  &__exclaim {
    color: $red;
    margin-left: rem-calc(8);
  }
}

.tr {
  &--highlight {
    background-color: rgba(255, 187, 20, 0.2);
  }
}

td,
th {
  padding: rem-calc(12) rem-calc(16);
}

th {
  cursor: pointer;
}

.th {
  // show caret down after th text
  &--sortAZ::after {
    content: '\f0d7';
    font-family: 'Font Awesome 5 Free';
    margin-left: rem-calc(8);
  }

  // show caret up after th text
  &--sortZA::after {
    content: '\f0d8';
    font-family: 'Font Awesome 5 Free';
    margin-left: rem-calc(8);
  }

  &--small {
    font-size: 12px;
  }
}

.td {
  &--left {
    text-align: left;
  }

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &--borderLeft {
    border-left: 1px solid $grayLter;
  }

  &--borderRight {
    border-right: 1px solid $grayLter;
  }
}

tr {
  border-bottom: 1px solid $grayLter;
}

.tr--noBorder {
  border: none;
}

.tr--top {
  border-top: 1px solid $grayLter;
}

// Used for login and signup pages to center the contents
.centerPage {
  margin: 0 auto rem-calc(32) auto;
  width: rem-calc(256);

  &__input {
    margin-top: rem-calc(16);
    width: 100%;

    &--wLabel {
      margin-top: rem-calc(8);
    }
  }

  &__error {
    font-size: rem-calc(14);
    margin-top: rem-calc(8);
  }

  &__button {
    margin-top: rem-calc(32);
    padding: 0;
    width: 100%;
  }

  &__p {
    margin-top: rem-calc(48);
    text-align: center;
  }

  &__link {
    margin-top: rem-calc(64);
  }
}

// Used for headers that have a back button
.backWrapper {
  padding: 0 rem-calc(180);
  position: relative;

  &__button {
    left: 0;
    position: absolute;
    top: 0;
  }

  &__icons {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;

    .iconButton {
      height: 48px;
      width: 48px;
    }
  }
}

// Used for showing progress through the steps of the invest process
.steps {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto rem-calc(48);
  padding-bottom: rem-calc(48); // allow space for ::after elements

  &__step {
    background: $gray;
    border: 4px solid $gray;
    border-radius: 50%;
    cursor: default;
    height: rem-calc(16);
    position: relative;
    width: rem-calc(16);

    &::after {
      color: $gray;
      font-size: rem-calc(12);
      position: absolute;
      top: rem-calc(32);
      transform: translateX(-50%);
      white-space: nowrap;
    }

    &--1::after {
      content: '1. Pledge';
    }

    &--2::after {
      content: '2. Profile';
    }

    &--3::after {
      content: '3. Documents';
    }

    &--4::after {
      content: '4. Finalize';
    }

    &--5::after {
      content: '5. Payment';
    }

    &--disabled {
      cursor: not-allowed;
    }

    &--active {
      background: $yellow;
      border: 4px solid $blue;

      &::after {
        @include bold;
        color: $blue;
      }
    }

    &--done {
      background: $blue;
      border-color: $blue;
    }
  }

  &__line {
    background: $gray;
    flex: 1;
    height: rem-calc(3);

    &--done {
      background: $blue;
    }
  }
}

// For dropdown menus used when necessary to replace selects
.dropdown {
  margin-top: rem-calc(8);
  position: relative;

  &__visible {
    @include input-style;
    padding-right: rem-calc(56);
    position: relative;
    transition: border-color 0.4s;

    &:hover {
      cursor: pointer;
    }

    &:active {
      border-color: $blue;
    }

    &::after {
      @include bold; // Must declare font weight or Font Awesome won't display
      content: '\f0d7';
      font-family: 'Font Awesome 5 Free';
      position: absolute;
      right: rem-calc(16);
      top: 50%;
      transform: translateY(-50%);
      z-index: 100;
    }
  }

  &__menu {
    @include shadow;
    background: white;
    display: none;
    left: 0;
    max-height: 25vh;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 2000;

    &--open {
      display: block;
    }
  }

  &__item {
    padding: rem-calc(16);
    transition: background-color 0.4s;

    &--disabled {
      color: $gray;
      cursor: not-allowed;

      .fa-pencil {
        color: $blue;
        cursor: pointer;
      }

      &:hover,
      &:active {
        background-color: white !important;
        color: $gray !important;
        cursor: not-allowed !important;
      }
    }

    &:hover,
    &:active {
      background-color: $yellow;
      cursor: pointer;
    }

    &--button {
      padding: 0 rem-calc(16);
    }
  }
}

/* Global rules for modals */
.modalGuts {
  padding: rem-calc(64) 10vw;

  &__h1 {
    font-size: rem-calc(56);
    text-align: center;

    &--thanks {
      margin: rem-calc(64) 0 rem-calc(48);
    }
  }

  &__p {
    font-size: rem-calc(24);
    margin-bottom: rem-calc(64);
    text-align: center;
  }

  &__button {
    margin: rem-calc(32) 0 0 auto;
  }

  &__section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__group {
    width: 100%;

    &--half {
      width: calc(50% - 8px);
    }

    &--quarter {
      width: calc(25% - 8px);
    }
  }

  &__input {
    margin-top: rem-calc(8);
    width: 100%;
  }
}

/* For showing a document link with icon */
.docs {
  &__row {
    align-items: center;
    border-top: 1px solid $grayLt;
    display: flex;
    justify-content: space-between;
    padding: rem-calc(16) 0;

    & + & {
      border-bottom: 1px solid $grayLt;
    }
  }

  &__icon {
    display: block;
    margin-right: 1rem;
  }

  &__link {
    display: flex;
    flex: 0 0 174px;

    // overflow: hidden;
    text-decoration: none;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    &--disabled {
      cursor: not-allowed;
    }
  }

  &__text {
    display: block;
    flex: 1;
    // overflow: hidden;
    text-decoration: underline;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    width: calc(100% - 95px);
  }
}

// For the tabs on the deal detail page
.tabPanel {
  background: $blue;
  padding: 0.5rem 3rem 0;

  &__container {
    align-items: center;
    display: flex;
    overflow-x: auto;
  }

  .tab {
    @include bold;
    color: #ffffff;
    cursor: pointer;
    font-size: rem-calc(14);
    height: 56px;
    line-height: 56px;
    padding: 0 2rem;

    &--selected {
      border-bottom: 3px solid $yellow;
      color: $yellow;
    }
  }
}

/* For list of investment profiles */
.profiles {
  &__header {
    margin-bottom: 1rem;
  }

  &__list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
  }

  &__instructions {
    margin-left: rem-calc(8);
  }

  &__item {
    align-items: center;
    display: flex;
    margin-bottom: rem-calc(16);
    position: relative;

    &:not(:last-of-type) {
      margin-right: 3rem;
    }

    &--deactivated {
      color: $grayDk;
    }
  }
}

// Used on the deal detail and investment detail pages
.logoHeader {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2rem;

  > * {
    margin-top: 1rem;
  }

  &__name {
    margin-right: 1rem;
  }

  &__icon {
    margin-right: 1rem;
  }

  &__logoContainer {
    align-items: center;
    display: flex;
  }

  &__logo {
    border-radius: 4px;
    margin-right: 1rem;
    width: rem-calc(48);
  }

  &__company {
    margin-right: 1rem;
  }
}

// Used on the deal detail and investment detail pages
.dealStats {
  align-items: center;
  border-bottom: 1px solid $grayLt;
  border-top: 1px solid $grayLt;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 0 2rem;

  .stat {
    margin-bottom: 1rem;

    label {
      margin-bottom: 1rem;
    }
  }
}

/* For badges */
.badger {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: rem-calc(48);
  min-width: rem-calc(48);
  position: relative;
  text-decoration: none;

  &__badge {
    @include bold;
    align-items: center;
    background: $red;
    border-radius: rem-calc(8);
    color: white;
    display: flex;
    font-size: rem-calc(12);
    height: rem-calc(16);
    justify-content: center;
    min-width: rem-calc(16);
    padding: 0 rem-calc(4);
    position: absolute;
    right: 0;
    top: 0;
  }
}

/* Global responsive rules
---------------------------------------- */

@media (min-width: 2000px) {
  .modalGuts {
    padding: rem-calc(64) rem-calc(200);
  }
}

@media (max-width: 899px) {
  // provide space for footer
  body {
    padding-bottom: rem-calc(184);
  }
}

@media (max-width: 799px) {
  .modalGuts {
    padding: rem-calc(64) 6vw;
  }
}

@media (max-width: 699px) {
  .modalGuts {
    &__group {
      &--half,
      &--quarter {
        width: 100%;
      }
    }
  }

  .backWrapper {
    padding: 0 rem-calc(56);

    &__text {
      display: none;
    }

    &__icons {
      display: flex;
      justify-content: center;
      position: static;
    }
  }
}

@media (max-width: 499px) {
  .modalGuts {
    &__h1 {
      font-size: rem-calc(40);
    }

    &__p {
      font-size: rem-calc(18);
    }
  }

  .profiles__list {
    display: block;
  }
}

@include breakpoint(768 down) {
  .tabPanel {
    padding: 0.5rem 0 0;
  }

  .dealStats {
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0;

    .stat {
      width: 50%;

      &--mobileFull {
        width: 100%;
      }
    }
  }
}
