/* TYPOGRAPHY (app-specific global rules--should only be imported once, in app.component.ts styleUrls)
---------------------------------------- */
@import 'mixins';
@import 'variables';

// General typographic rules
html,
body,
textarea {
  @include sans;
  @include normal;
  color: $blue;
  font-size: 100%; // 16px in most browsers. If you change this value, you'll want to change the $rem-base variable in _functions.scss
}

h1 {
  @include playfair;
  @include bold;
  @include italic;
  font-size: rem-calc(48);
  margin-bottom: rem-calc(32);
  text-align: center;
}

.h1Small {
  font-size: rem-calc(32);
}

h2 {
  @include oswald;
  @include bold;
  font-size: rem-calc(21);
  letter-spacing: 2px;
  margin-bottom: rem-calc(16);
  margin-top: 0;
  text-transform: uppercase;
}

h3 {
  @include bold;
  font-size: rem-calc(24);
}

h3,
h4,
h5,
h6,
p {
  margin: 0;
}

label {
  @include bold;
  display: block;
  font-size: rem-calc(12);
  margin-top: rem-calc(16);
}

.hint {
  @include normal;
  color: $grayDk;
  font-size: rem-calc(12);
  margin-top: rem-calc(8);
}

.error {
  @include bold;
  color: $red;
  font-size: rem-calc(12);
  margin-top: rem-calc(8);
}

.fineprint {
  @include normal;
  color: $gray;
  font-size: rem-calc(12);

  &--right {
    text-align: right;
  }
}

.instructions {
  color: $gray;
}

// Icon fonts
$font-path: '../assets/fonts';

@font-face {
  font-display: block;
  font-family: 'icomoon';
  font-style: normal;
  font-weight: normal;
  src: url('#{$font-path}/icomoon.eot');
  src: url('#{$font-path}/icomoon.eot#iefix') format('embedded-opentype'),
    url('#{$font-path}/icomoon.ttf') format('truetype'), url('#{$font-path}/icomoon.woff') format('woff'),
    url('#{$font-path}/icomoon.svg#icomoon') format('svg');
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: never;
  text-transform: none;
}

.icon-check::before {
  content: '\e900';
}

.icon-close::before {
  content: '\e901';
}

.icon-facebook::before {
  content: '\e902';
}

.icon-instagram::before {
  content: '\e903';
}

.icon-linkedin::before {
  content: '\e904';
}

.icon-twitter::before {
  content: '\e905';
}

.icon-menu::before {
  content: '\e906';
}

/* Global typography-related media queries */

@media (max-width: 599px) {
  h1 {
    font-size: rem-calc(32);
  }
}
