/* STARTSTUDIO DEFAULTS (should only be imported once, in app.component.ts styleUrls)
---------------------------------------- */
@import 'functions';
@import 'mixins';
@import 'variables';

body {
  margin: 0;
  min-width: 320px;
  padding: 0;
}

* {
  box-sizing: border-box;
}

/* ng-cloak -- TODO: update for Angular2 */
[ng\:cloak],
[ng-cloak],
.ng-cloak {
  display: none !important;
}

/* List styling -- use 'disc' mixin to restore default styling */
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Remove shadow and outline on focus */
input[type='name'],
input[type='email'],
input[type='text'],
input[type='tel'],
input[type='submit'],
input[type='number'],
input[type='password'],
input[type='url'],
textarea,
button,
a,
select {
  &:focus {
    box-shadow: 0, 0, 0, transparent;
    outline: none !important;
    text-decoration: none;
  }
}

input:focus:invalid:focus,
textarea:focus:invalid:focus,
select:focus:invalid:focus {
  box-shadow: 0, 0, 0, transparent;
  outline: none;
}

/* Add button cursor styles */
button,
input[type='submit'],
input[type='button'] {
  cursor: pointer;
}

button:disabled,
input[type='submit']:disabled,
input[type='button']:disabled {
  cursor: not-allowed;
}

/* images can't be wider than their containers */
img {
  max-width: 100%;
}

/* tables inherit font size (some browsers set a default table font size) */
table {
  font-size: inherit;
}

.selectContainer {
  position: relative;

  select {
    appearance: none;
    padding-right: 40px;
    width: 100%;

    &::-ms-expand {
      display: none;
    }
  }

  &::after {
    @include bold; // Must declare font weight or Font Awesome won't display
    content: '\f0d7';

    font-family: 'Font Awesome 5 Free';
    pointer-events: none;
    position: absolute;
    right: rem-calc(16);
    top: 50%;

    top: 18px;
    // transform: translateY(-50%);
    // transform: rotate(90deg);
    z-index: 100;
  }
}

/* set cursor to pointer for specific elements -- TODO: update for Angular2 */
a,
[ui-sref],
[ng-click] {
  cursor: pointer;
  outline: none;
}

/* STARTSTUDIO CONTAINER */
.ssContainer {
  margin: 0 auto;
  max-width: rem-calc(1440);
  padding: 0 rem-calc(16);
  width: 100%;
}

/* STARTSTUDIO SPINNER */
.spinnerContainer {
  margin: 0 auto;
  padding: 2rem 0;
  text-align: center;
}

.ssSpinner {
  &::before {
    animation: ssSpin 2s linear infinite;
    border: 6px solid $gray;
    border-bottom-color: $blue;
    border-radius: 50%;
    border-top-color: $blue;
    content: '';
    display: inline-block;
    height: 36px;
    width: 36px;
  }
}
@keyframes ssSpin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
