.hero {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: rem-calc(96);
  padding: rem-calc(32) 0;
  position: relative;

  &__titleContainer {
    position: absolute;
    top: rem-calc(200);
    text-align: center;
  }

  &__title {
    @include bold;
    @include playfair;
    color: $blue;
    font-style: italic;
    margin: 0;

    &--40 {
      font-size: rem-calc(40);
      letter-spacing: 0.5px;
    }

    &--60 {
      font-size: rem-calc(60);
      letter-spacing: 0.5px;
      margin-bottom: rem-calc(-40);
    }

    &--132 {
      font-size: rem-calc(132);
      letter-spacing: -2px;
      margin-bottom: rem-calc(48);
    }
  }

  &__img {
    display: block;
    width: 70%;
  }

  &__social {
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
  }

  &__link {
    display: block;
    font-size: rem-calc(20);
    margin-bottom: 4vw;
    text-decoration: none;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__button {
    bottom: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
}

@media (max-width: 1024px) {
  .hero {
    flex-direction: column;
    margin-bottom: rem-calc(32);

    &__button {
      margin: 2em 0;
      position: static;
      transform: none;
    }

    &__social {
      display: flex;
      position: static;
      transform: none;
    }

    &__link {
      margin-bottom: 0;
      margin-right: 8vw;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .hero {
    margin-bottom: 0;

    &__img {
      width: 100%;
    }
  }
}
