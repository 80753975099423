/* APP-SPECIFIC GLOBAL VARIABLES
---------------------------------------- */

// colors
$grayLter: #f5f2ee;
$grayLt: #e3dcd0;
$gray: #b9b2a8;
$grayDk: #797166;

$yellow: #ffbb14;
$blue: #000c3b;
$red: #ce0000;

$border: $grayLt;
